import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.scss";
import AmmoGopher from "../../assets/gopherarmor.png";
import BodyArmor from "../../assets/BodyArmorSearch.png";
import { useEffect, useState } from "preact/hooks";

export const getLogo = () => {
  const config = {
    "gopherarmor.com": AmmoGopher,
    "bodyarmorsearch.com": BodyArmor,
    "0.0.0.0:8080": BodyArmor,
    "goforarmor.com": AmmoGopher,
    "armorgopher.com": AmmoGopher,
  };
  const domain = window.location.host;
  return config[domain] || BodyArmor;
};

const Header = () => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    setIcon(getLogo());
  }, []);

  return (
    <header className={style.header}>
      <Link href="/">{icon && <img className={style.logo} src={icon} />}</Link>
    </header>
  );
};

export default Header;
