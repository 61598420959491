import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';
import Footer from './footer';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Privacy from '../routes/privacy';
import Faq from '../routes/faq';
import Terms from '../routes/terms';

const App = () => (
	<div id="app">
		<Header />
		<div style={{paddingBottom: 100}}>
		<Router>
			<Home path="/" />
			<Faq path="/faq" />
			<Privacy path="/privacy" />
			<Terms path="/terms" />
		</Router>
		</div>
		<Footer />
	</div>
)

export default App;
