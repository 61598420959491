import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.scss";
import { getLogo } from "../header";
import { useEffect, useState } from "preact/hooks";
const Footer = () => {
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLogo(getLogo());
  }, []);
  return (
    <div className={style.footer}>
      {logo && <img src={logo} style={{ height: 30,marginBottom: 5, filter: 'invert(1)' }} />}
      <div className={style.links}>
        {[
          ["Terms & Conditions", "/terms"],
          ["Privacy Policy", "/privacy"],
          ["FAQ", "/faq"],
        ].map(([title, link]) => {
          return <Link href={link}>{title}</Link>;
        })}
      </div>
      Copyright &copy; {new Date().getFullYear()} Gopher Tech LLC
    </div>
  );
};

export default Footer;
